import React from 'react';

export const SelectedIcon: React.FC = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="selected_icon_id"
    >
        <path
            d="M10 13C8.34315 13 7 11.6569 7 10H-7C-7 19.3888 0.611159 27 10 27V13ZM13 10C13 11.6569 11.6569 13 10 13V27C19.3888 27 27 19.3888 27 10H13ZM10 7C11.6569 7 13 8.34315 13 10H27C27 0.611159 19.3888 -7 10 -7V7ZM10 -7C0.611159 -7 -7 0.611159 -7 10H7C7 8.34315 8.34315 7 10 7V-7Z"
            fill="currentColor"
        />
    </svg>
);

export default SelectedIcon;
