import { useState } from 'react';

import { api } from 'api';
import { DocumentName } from 'models/Document.model';
import dayjs from 'dayjs';
import config from 'api/config';

const ERROR = 'Не удалось скачать файл';

type TUseFileDownload = {
    error: string;
    onDownload: (fileName: DocumentName) => void;
};
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const getReadableFileName = (fileName: DocumentName) => {
    switch (fileName) {
        case 'tariffplanresidentcard':
            return 'Тарифы Карты Жителя.pdf';
        default:
            return `${dayjs().format('DD.MM.YYYY')}.pdf`;
    }
};

const downloadBlob = (blob: Blob, fileName: DocumentName) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    if (isSafari) {
        link.setAttribute('download', getReadableFileName(fileName));
    } else {
        link.target = '_blank';
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const downloadLink = (url: string) => {
    const link = document.createElement('a');

    link.href = url;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const useFileDownload = (
    channel: string,
    onLoaderStart: () => void,
    onLoaderStop: () => void
): TUseFileDownload => {
    const [error, setError] = useState('');

    const onFileDownload = (fileName: DocumentName) => {
        setError('');
        onLoaderStart();

        api.getDocument(fileName)
            .then(data => downloadBlob(data, fileName))
            .catch(error => {
                const errorMessage = error?.response?.data?.error
                    ? error.response.data.error
                    : ERROR;

                setError(errorMessage);
            })
            .finally(() => {
                onLoaderStop();
            });
    };

    const onLinkDownload = (fileName: DocumentName) => {
        const url = `${config.API_URL}/api/v1/Document?filename=${fileName}`;
        downloadLink(url);
    };

    return {
        error,
        onDownload: channel === 'CITCARD_Preauth' ? onLinkDownload : onFileDownload,
    };
};
