import SuccessIcon from 'assets/images/Success.svg';
import ErrorIcon from 'assets/images/Error.svg';
import { Results } from 'models';

interface Message {
    icon: string;
    primaryText: string;
    secondaryText: string;
}

export const resultMessages: Record<Results, Message> = {
    [Results.SUCCESS]: {
        icon: SuccessIcon,
        primaryText: 'Заявка успешно отправлена!',
        secondaryText:
            'В случае необходимости с вами свяжется сотрудник банка.\nУточнить статус готовности карты вы можете в приложении Ак Барс Онлайн или из смс сообщения от банка.',
    },
    [Results.ERROR]: {
        icon: ErrorIcon,
        primaryText: 'Что-то пошло не так',
        secondaryText: 'Попробуйте отправить заявку позднее',
    },
};
