import React from 'react';
import classnames from 'classnames';
import { Context } from './context';
import { StepId } from './typings';

interface Props {
    index: number;
    stepId: StepId;
    setCurrentIndex: (index: number) => void;
}

const CLASS_NAME = 'stepper__head';

const Header: React.FC<Props> = ({ index, stepId, setCurrentIndex }) => {
    const { isLoading, goAt, getStep, getCurrentStep } = React.useContext(Context);
    const current = getCurrentStep();
    const { loading, completed, error, disabled, className, title } = getStep(stepId);

    const active = Boolean(current && current.stepId === stepId);
    const disabled$ = disabled || isLoading() || !Boolean(active || completed || error);

    const onClick = () => process.env.NODE_ENV === 'development' && goAt(stepId);

    React.useEffect(() => {
        setCurrentIndex(current.index);
    }, [current]);

    return (
        <div
            tabIndex={index}
            onClick={onClick}
            className={classnames(className, CLASS_NAME, {
                [`${CLASS_NAME}--loading`]: loading,
                [`${CLASS_NAME}--completed`]: completed,
                [`${CLASS_NAME}--error`]: error,
                [`${CLASS_NAME}--active`]: active,
                [`${CLASS_NAME}--disabled`]: disabled$,
            })}
            data-testid="step_header_id"
        >
            <span className="step-number">{index}</span>
            <span className="step-name">{title}</span>
        </div>
    );
};

export default React.memo(Header);
