import dayjs from 'dayjs';
import timeZonePlugin from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';

dayjs
    .extend(timeZonePlugin)
    .extend(relativeTime)
    .extend(localizedFormat)
    .extend(customParseFormat)
    .locale('ru');

export default dayjs;
