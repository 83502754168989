import { omit } from 'lodash';
import { FormData } from 'models';

export const prepareData = (data: Partial<FormData>) => {
    if (!data) return {};

    const preparedData = {
        ...omit(data, ['token', 'requestId', 'isAuth']),
        phone: formatPhone(data.phone),
    };

    return preparedData;
};

export const formatPhone = (phone: string): string => {
    if (!phone) return '';

    return phone.split('+7')[1];
};
