import { Dayjs } from 'dayjs';
import dayjs from './dayjs';

/**
 * Валидация даты
 * @param string Дата в формате дд.мм.гггг
 * @return boolean true or false
 */
export function isValidDate(str) {
    if (!dayjs(str, 'DD.MM.YYYY', true).isValid()) {
        return false;
    }

    if (dayjs(str, 'DD.MM.YYYY').year() < 1910) {
        return false;
    }

    return true;
}

/**
 * Функция форматирования даты к формату ДД.ММ.ГГГГ
 * @param date Дата
 */
const formatDate = (date: string | Dayjs) => dayjs(date, 'DD.MM.YYYY');

/**
 * Валидация даты рождения. Должно быть 18 лет для заказа карты
 * @param date Дата рождения
 * @returns boolean
 */
export const isValidBirthDate = (date: string): boolean => {
    const minAge = 18; // Минимальный возраст, с которого можно заказать карту

    // Проверяем возраст. Не должен быть меньше 18 лет
    const isLessThanMinAge = dayjs().diff(formatDate(date), 'year') < minAge;

    if (isLessThanMinAge) {
        return false;
    }

    return true;
};

/**
 * Валидация даты выдачи паспорта. Должна быть 14 лет с даты рождения
 * @param date Дата выдачи паспорта
 * @param birthDate Дата рождения
 * @returns boolean. False если валидация не пройдена, true - если пройдена
 */
export const isValidPassportDate = (date: string, birthDate: string): boolean => {
    const minDiff = 14; // Минимальная разница в датах (14 лет)

    const isLessThanMinDiff =
        formatDate(date).diff(formatDate(birthDate), 'year') < minDiff;

    if (isLessThanMinDiff) {
        return false;
    }

    return true;
};

/**
 * Валидация даты выдачи паспорта. Не должна быть больше сегодняшней даты
 * @param date Дата выдачи паспорта
 */
export const isDateMoreThanToday = (date: string) => {
    const now = dayjs().startOf('date');
    const isMoreThanToday = formatDate(date).diff(formatDate(now), 'day') > 0;

    if (isMoreThanToday) {
        return false;
    }

    return true;
};
