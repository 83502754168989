import React, { useEffect } from 'react';
import { TextField } from 'components/TextField';
import { Box } from 'components/Box';
import { StepperAction, StepperContent, StepperContext } from 'components/Stepper';
import { useForm } from 'react-hook-form';
import { STEP1 } from './Step1';
import { Loading } from 'components/Loading';
import {
    isValidPassportDate,
    REQUIRED,
    PASSPORT_SERIES_RULE,
    PASSPORT_NUMBER_RULE,
    isValidDate,
    isDateMoreThanToday,
    PASSPORT_DEPARTMENT_CODE_RULE,
    ADDRESSES_RULE,
} from 'utils';
import { ApiResponse, CreateDraftResponse, FormData } from 'models';
import { api } from 'api';
import { Results as ResultsEnum } from 'models';
import { LoadingContext } from 'steps/loadingContext';

export const STEP2 = 'step-two';

interface Props {
    onCreateDraftError?: (error: string) => void;
}

export const Step2: React.FC<Props> = ({ onCreateDraftError }) => {
    const { isLoading, setLoading } = React.useContext(LoadingContext);
    const { resolve, getData, goAt, setData } = React.useContext(StepperContext);
    const { register, handleSubmit, errors, control } = useForm({
        defaultValues: getData(),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    const stepperData = getData();

    const onSubmit = data => {
        resolve(data);
    };

    const back = () => {
        goAt(STEP1);
    };

    const createDraft = async (formData: FormData) => {
        setLoading(STEP2, true);
        try {
            const data: ApiResponse<CreateDraftResponse> = await api.sendDraft(
                formData,
                'POST'
            );
            if (data.success) {
                const { applicationId } = data.result;
                setData({ applicationId });
                setLoading(STEP2, false);
            } else {
                onCreateDraftError(ResultsEnum.ERROR);
            }
        } catch {
            onCreateDraftError(ResultsEnum.ERROR);
        }
    };

    useEffect(() => {
        if (stepperData.token) {
            createDraft(stepperData);
        }
    }, []);

    return (
        <StepperContent
            onSubmit={handleSubmit(onSubmit)}
            actionsLeft={
                <>
                    {isLoading(STEP2) ? (
                        <Loading />
                    ) : (
                        <StepperAction type="button" onClick={back} arrowLeft white>
                            Назад
                        </StepperAction>
                    )}
                </>
            }
            actionsRight={
                <StepperAction
                    align="right"
                    type="submit"
                    arrowRight
                    disabled={isLoading(STEP2)}
                >
                    Продолжить
                </StepperAction>
            }
        >
            <div className="container-fluid row flex flex-wrap">
                <Box>
                    <TextField
                        control={control}
                        name="passportSeries"
                        error={errors.passportSeries}
                        mask="9999"
                        title="Серия паспорта"
                        required
                        rules={{
                            required: REQUIRED,
                            pattern: PASSPORT_SERIES_RULE,
                        }}
                    />
                </Box>
                <Box>
                    <TextField
                        control={control}
                        name="passportNumber"
                        error={errors.passportNumber}
                        mask="999999"
                        title="Номер паспорта"
                        required
                        rules={{ required: REQUIRED, pattern: PASSPORT_NUMBER_RULE }}
                    />
                </Box>
                <Box>
                    <TextField
                        control={control}
                        name="passportDate"
                        error={errors.passportDate}
                        title="Дата выдачи"
                        placeholder="ДД.ММ.ГГГГ"
                        mask="99.99.9999"
                        required
                        rules={{
                            required: REQUIRED,
                            validate: {
                                isValidDatePassportDate: value =>
                                    isValidPassportDate(
                                        value,
                                        stepperData.birthdayDate
                                    ) || 'Меньше 14 лет с даты рождения',
                                isValidDate: value =>
                                    isValidDate(value) || 'Некорректная дата',
                                isMoreThanToday: value =>
                                    isDateMoreThanToday(value) ||
                                    'Дата не должна быть больше сегодняшней даты',
                            },
                        }}
                    />
                </Box>
                <Box>
                    <TextField
                        control={control}
                        name="passportDepartmentCode"
                        error={errors.passportDepartmentCode}
                        title="Код подразделения"
                        mask="999-999"
                        required
                        rules={{
                            required: REQUIRED,
                            pattern: PASSPORT_DEPARTMENT_CODE_RULE,
                        }}
                    />
                </Box>
                <Box>
                    <TextField
                        name="passportIssuedBy"
                        title="Кем выдан"
                        error={errors.passportIssuedBy}
                        required
                        inputRef={register({
                            required: REQUIRED,
                            pattern: ADDRESSES_RULE,
                        })}
                    />
                </Box>
                <Box>
                    <TextField
                        name="birthPlace"
                        title="Место рождения"
                        error={errors.birthPlace}
                        required
                        inputRef={register({
                            required: REQUIRED,
                            pattern: ADDRESSES_RULE,
                        })}
                    />
                </Box>
            </div>
        </StepperContent>
    );
};

export default Step2;
