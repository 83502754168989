import React from 'react';
import cx from 'classnames';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';

interface Props {
    name: string;
    title?: string;
    placeholder?: string;
    defaultValue?: any;
    error?: any;
    disabled?: boolean;
    required?: boolean;
    mask?: string;
    inputRef?: any;
    value?: any;
    iconLeft?: any;
    iconRight?: any;
    children?: any;
    control?: any;
    options: { label: string; value: any }[];
    isSearchable?: boolean;
    onChangeSelect?: any;
    opacity?: number;
}

export const Select: React.FC<Props> = ({
    children,
    inputRef,
    error,
    title,
    required,
    options,
    name,
    control,
    defaultValue,
    isSearchable,
    onChangeSelect,
    opacity = 0,
    ...props
}) => {
    return (
        <div className="text-field" data-testid="select_id">
            <span className="text-field__title">{title + (required ? '*' : '')}</span>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ onChange, value, name, ref }) => (
                    <ReactSelect
                        ref={ref}
                        classNamePrefix="react-select"
                        className={cx('react-select', {
                            'react-select__error': Boolean(error),
                        })}
                        styles={{
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                opacity: opacity,
                            }),
                        }}
                        options={options}
                        isSearchable={isSearchable}
                        noOptionsMessage={() => 'Нет результатов'}
                        value={options?.find(c => c.value === value)}
                        onChange={val => {
                            if (onChangeSelect) onChangeSelect(val.value, val.label);
                            onChange(val.value);
                        }}
                        {...props}
                    />
                )}
            />
            {error && (
                <span className="text-field__error-message" style={{ marginTop: '5px' }}>
                    {error}
                </span>
            )}
        </div>
    );
};
