import React from 'react';
import { Login } from './Login';
import { Success } from './Success';
import { StepperContext } from 'components/Stepper';

interface Props {
    esiaError: string;
}

export const DEFAULT_ESIA_ERROR =
    'Сервис ЕСИА временно недоступен. Пожалуйста, заполните заявку вручную';

export const EsiaAuthBanner: React.FC<Props> = ({ esiaError }) => {
    const { getData } = React.useContext(StepperContext);
    const [loginError, setLoginError] = React.useState<string>('');
    let stepperData = getData();

    const errorHandler = React.useCallback(isError => {
        setLoginError(isError);
    }, []);

    return (
        <>
            <div className="esia-auth-banner">
                {stepperData.isAuth ? (
                    <Success />
                ) : (
                    <Login onErrorRequest={errorHandler} />
                )}
            </div>
            {(!!loginError || !!esiaError) && (
                <div className={`text-field__error-message`} data-testid="esia_error_id">
                    {esiaError || loginError}
                </div>
            )}
        </>
    );
};

export default EsiaAuthBanner;
