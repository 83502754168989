import { Settlement, FormData } from 'models';
import { prepareData } from 'utils';
import { DocumentName } from 'models/Document.model';
import apiConfig from './config';

type TMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
type THeaders = {
    Accept: string;
    'Content-Type': string;
    Authorization?: string;
    [key: string]: string;
};

class API {
    private sendRequest = async (
        method: TMethod,
        url: string,
        body?: { [key: string]: string | number },
        token?: string,
        responseType = 'json'
    ): Promise<any> => {
        const headers: THeaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
        };

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        const config: {
            method: TMethod;
            headers: THeaders;
            body?: string;
        } = {
            method,
            headers,
        };

        if (body) {
            config.body = JSON.stringify(body);
        }

        try {
            const response = await fetch(`${apiConfig.API_URL}/api/v1/${url}`, config);

            if (responseType === 'blob') {
                return await response.blob();
            } else {
                const jsonData = await response.json();

                return await Promise.resolve(jsonData);
            }
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    //Методы;

    /**
     * Отправка кода для подтверждения номера телефона
     * @param phone номер телефона
     */
    public sendCode = async (phone: string) => {
        try {
            const data = await this.sendRequest('POST', 'PhoneAuthentication/SendCode', {
                phone,
            });

            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    /**
     * Подтверждение кода, отправленного на телефон
     * @param phone номер телефона
     * @param code код подтверждения
     * @param provider канал подачи заявки
     */
    public confirmCode = async (phone: string, code: string, provider: string) => {
        try {
            const data = await this.sendRequest(
                'POST',
                'PhoneAuthentication/ConfirmCode',
                {
                    phone,
                    code,
                    provider,
                }
            );

            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    /**
     * Получение подсказок по адресу от ДаДаты
     * @param value значение, введенное в текстовое поле (например, Казань)
     * @param fiasId передается для ограничения подсказок по определенному городу. Если не передан, то по всей России
     */
    public suggestAddress = async (value: string, fiasId: string) => {
        let url = '';
        if (fiasId) {
            url = `DaData/suggestAddress?query=${value}&fiasId=${fiasId}`;
        } else {
            url = `DaData/suggestAddress?query=${value}`;
        }

        try {
            const data = await this.sendRequest('GET', url);
            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    /**
     * Получение подсказок по городам и населенным пунктам от ДаДаты
     * @param query значение, введенное в текстовое поле
     */
    public settlementsDelivery = async (query: string) => {
        let url = `DaData/getSettlements?query=${query}`;
        try {
            const data = await this.sendRequest('GET', url);
            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    /**
     * Запрос на получение информации о доставке и отделениях в выбранном городе
     * @param settlement Населенный пункт, полученный от ДаДаты
     */
    public endpointDelivery = async (settlement: Settlement) => {
        const { displayValue, value } = settlement;
        try {
            const data = await this.sendRequest('POST', 'Delivery/endpoints', {
                displayValue,
                value,
            });
            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    /**
     * Работа с черновиком заявки
     * @param formData данные из заявки
     * @param method POST для создания черновика, PUT - для обновления
     */
    public sendDraft = async (formData: Partial<FormData>, method: 'PUT' | 'POST') => {
        const preparedData = prepareData(formData);
        try {
            const data = await this.sendRequest(
                method,
                'ApplicationDraft',
                {
                    ...preparedData,
                },
                formData.token
            );

            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    /**
     * Старт процедуры выпуска карты по черновику
     * @param applicationId Id заявки
     * @param token Токен авторизации
     */
    public sendApplication = async (applicationId: string, token: string) => {
        try {
            const data = await this.sendRequest(
                'POST',
                `ApplicationDraft/${applicationId}`,
                {},
                token
            );

            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    /**
     * Авторизация через ЕСИА
     * @param callback url, на который нужно редиректить
     */
    public esiaAuth = async (callback: string) => {
        try {
            const data = await this.sendRequest(
                'GET',
                `Auth/esiaAuth?callback=${callback}`
            );
            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    /**
     * Получение данных о пользователе из кэша
     * @param requestId Id, полученный при авторизации
     * @param provider Канал подачи заявки
     * @returns
     */
    public userInfo = async (requestId: string, provider: string) => {
        try {
            const data = await this.sendRequest(
                'GET',
                `Auth/userInfo?requestId=${requestId}&provider=${provider}`
            );
            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    //Получение данных после авторизации на сайте
    /**
     * Получение данных о пользователе из ЦП
     * @param state Параметр, полученный при авторизации
     * @param code Параметр, полученный при авторизации
     * @param provider Канал подачи заявки
     */
    public esiaUserInfo = async (state: string, code: string, provider: string) => {
        try {
            const data = await this.sendRequest('POST', 'Auth/esiaUserInfo', {
                state,
                code,
                provider,
            });
            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    /**
     * Скачивание файла из ЕА
     * @param fileName Название файла
     * @returns
     */
    public getDocument = async (fileName: DocumentName) => {
        try {
            const data = await this.sendRequest(
                'GET',
                `Document?fileName=${fileName}`,
                undefined,
                undefined,
                'blob'
            );
            return data;
        } catch (error) {
            return await Promise.reject(error);
        }
    };
}

// eslint-disable-next-line
export default new API();
