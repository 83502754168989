import React from 'react';
import { resultMessages } from './constants';
import { Button } from 'components/Button';
import { Channel } from 'models';

interface Props {
    result: string;
}

export const Result: React.FC<Props> = ({ result }) => {
    const provider = new URLSearchParams(window.location.search).get('provider');
    const resultMessage = React.useMemo(() => resultMessages[result], [result]);
    const isButtonShow = provider === Channel.PreAuth && result === 'success';

    return (
        <div className="form-success">
            <div className="form-success__success-icon">
                <img src={resultMessage?.icon} alt="" />
            </div>
            <div className="form-success__header" data-testid="result_header">
                <h3>{resultMessage?.primaryText}</h3>
            </div>
            <div className="form-success__text">
                <span>{resultMessage?.secondaryText}</span>
            </div>

            {isButtonShow && (
                <div className="form-success__button">
                    <Button
                        onClick={() =>
                            (window.location.href = 'https://www.cardj-widget-finish.ru')
                        }
                    >
                        Хорошо
                    </Button>
                </div>
            )}
        </div>
    );
};
