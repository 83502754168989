import {
    NAME_WITH_TWO_WORDS,
    PHONE_NUMBER,
    PASSPORT_SERIES,
    PASSPORT_NUMBER,
    PASSPORT_DEPARTMENT_CODE,
    ADDRESSES,
} from './regex';

export const REQUIRED = { value: true, message: 'Обязательное поле' };
export const NAME_RULE = {
    value: NAME_WITH_TWO_WORDS,
    message: 'Доступны только русские буквы',
};

export const PHONE_RULE = {
    value: PHONE_NUMBER,
    message: 'Некорректный номер телефона',
};

export const PASSPORT_SERIES_RULE = {
    value: PASSPORT_SERIES,
    message: 'Некорректная серия паспорта',
};

export const PASSPORT_NUMBER_RULE = {
    value: PASSPORT_NUMBER,
    message: 'Некорректный номер паспорта',
};

export const PASSPORT_DEPARTMENT_CODE_RULE = {
    value: PASSPORT_DEPARTMENT_CODE,
    message: 'Некорректный код подразделения',
};

export const ADDRESSES_RULE = {
    value: ADDRESSES,
    message: 'Доступны только русские буквы',
};
