import React from 'react';
import cx from 'classnames';
import ArrowGreyLeft from 'assets/images/ArrowGreyLeft.svg';
import ArrowWhiteRight from 'assets/images/ArrowWhiteRight.svg';

const ALIGN_LEFT = 'left';
const ALIGN_RIGHT = 'right';

type Align = 'left' | 'right';

interface Props {
    text?: string;
    type?: 'button' | 'submit' | 'reset';
    arrowLeft?: boolean;
    fullWidth?: boolean;
    arrowRight?: boolean;
    white?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
    align?: Align;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button: React.FC<Props> = ({
    text,
    type = 'button',
    white,
    arrowLeft,
    arrowRight,
    disabled,
    children,
    align,
    className,
    onClick,
    fullWidth,
}) => {
    let value = children || text;
    if (arrowLeft)
        value = (
            <>
                <img
                    src={ArrowGreyLeft}
                    alt="Стрелка влево"
                    className="form-button__arrow-left"
                    data-testid="arrow_left"
                />
                {children || text}
            </>
        );
    if (arrowRight)
        value = (
            <>
                {children || text}
                <img
                    src={ArrowWhiteRight}
                    alt="Стрелка вправо"
                    className="form-button__arrow-right"
                    data-testid="arrow_right"
                />
            </>
        );
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={cx(
                className,
                'form-button',
                { 'form-button_white': white },
                { 'form-button_blue': !white },
                { 'form-button_disabled': disabled },
                { 'form-button_full-width': fullWidth },
                { 'form-button-align--left': align === ALIGN_LEFT },
                { 'form-button-align--right': align === ALIGN_RIGHT }
            )}
            data-testid="button_id"
        >
            {value}
        </button>
    );
};
