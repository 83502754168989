import React from 'react';
import { Channel } from 'models';

export type ChannelContextType = {
    channel?: Channel;
};

export const ChannelContext = React.createContext<ChannelContextType>({});

export const ChannelProvider = ChannelContext.Provider;
