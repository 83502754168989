import React from 'react';
import cx from 'classnames';
import './styles.scss';

interface Props {
    text: string;
    onClick: () => void;
    disabled: boolean;
}

export const SendAgainButton: React.FC<Props> = ({ text, onClick, disabled }) => {
    return (
        <div
            className={cx('send-button', {
                'send-button__disabled': disabled,
                'send-button__active': !disabled,
            })}
            onClick={onClick}
            data-testid="send_again_button_id"
        >
            {text}
        </div>
    );
};
