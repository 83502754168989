import React, { useEffect, useState } from 'react';
import Stepper, { Step } from 'components/Stepper';
import { Step1, Step2, Step3, Step4, STEP1, STEP2, STEP3, STEP4 } from 'steps';
import { Result, Loading, StepperController, DEFAULT_ESIA_ERROR } from 'components';
import LoadingProvider from 'steps/loadingContext';
import { deleteStorage } from 'utils/localStorage';
import { LocalStorageKeys, ApiResponse, EsiaUser, Channel } from 'models';
import { api } from 'api';
import { getQuery } from 'utils/getQuery';
import { getWidgetTitle } from 'utils/getWidgetTitle';
import { ChannelContext } from 'channelContext';

import 'styles/App.scss';

const App: React.FC = () => {
    const { channel } = React.useContext(ChannelContext);
    const [result, setResult] = useState<string>();
    const stepperControllerRef = React.useRef<StepperController>();
    const [errorRequest, setErrorRequest] = React.useState<string>('');
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [defaultData, setDefaultData] = React.useState<any>();
    const [showAuthBanner, setShowAuthBanner] = React.useState<boolean>(true);

    const getUserInfo = async (requestId: string) => {
        setErrorRequest('');
        try {
            const data: ApiResponse<EsiaUser> = await api.userInfo(requestId, channel);
            if (data.success) {
                setDefaultData({
                    ...data.result,
                    requestId,
                    isAuth: true,
                });
            } else if (data.errorCode > 0) {
                setDefaultData({});
                setErrorRequest(data.error);
            } else {
                setDefaultData({});
                setErrorRequest(DEFAULT_ESIA_ERROR);
            }
            setLoaded(true);
        } catch (e) {
            setErrorRequest(DEFAULT_ESIA_ERROR);
            setDefaultData({});
            setLoaded(true);
        } finally {
            deleteStorage(LocalStorageKeys.EsiaRequestId);
        }
    };

    const getEsiaUserInfo = async (state: string, code: string) => {
        setErrorRequest('');
        try {
            const data: ApiResponse<EsiaUser> = await api.esiaUserInfo(
                state,
                code,
                channel
            );
            if (data.success) {
                setDefaultData({
                    ...data.result,
                    state,
                    code,
                    isAuth: true,
                });
                setShowAuthBanner(false);
            } else {
                setDefaultData({});
                setErrorRequest(DEFAULT_ESIA_ERROR);
            }
            setLoaded(true);
        } catch (e) {
            setErrorRequest(DEFAULT_ESIA_ERROR);
            setDefaultData({});
            setLoaded(true);
        } finally {
            deleteStorage(LocalStorageKeys.EsiaRequestId);
        }
    };

    useEffect(() => {
        const requestId = localStorage.getItem(LocalStorageKeys.EsiaRequestId);
        const state = getQuery('state');
        const code = getQuery('code');

        if (requestId) {
            getUserInfo(requestId);
        } else if (code && state) {
            getEsiaUserInfo(state, code);
        } else {
            setLoaded(true);
        }
    }, []);

    if (!loaded)
        return (
            <div className="card-order-widget-cardj">
                <div className="main-container container">
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <Loading />
                    </div>
                </div>
            </div>
        );

    return (
        <LoadingProvider>
            <div className="card-order-widget-cardj">
                <div className="main-container container">
                    {!!result ? (
                        <Result result={result} />
                    ) : (
                        <div>
                            <h2 className="main-title">{getWidgetTitle(channel)}</h2>
                            <Stepper
                                initialStep={STEP1}
                                contextRef={stepperControllerRef}
                                defaultData={defaultData}
                                showAuthBanner={showAuthBanner}
                            >
                                <Step stepId={STEP1} title="Контактная информация">
                                    <Step1 esiaError={errorRequest} />
                                </Step>
                                <Step
                                    stepId={STEP2}
                                    title="Паспортные данные"
                                    description=""
                                >
                                    <Step2 onCreateDraftError={setResult} />
                                </Step>
                                <Step stepId={STEP3} title="Адрес регистрации">
                                    <Step3 />
                                </Step>
                                <Step stepId={STEP4} title="Выдача карты">
                                    <Step4 setResult={setResult} />
                                </Step>
                            </Stepper>
                        </div>
                    )}
                </div>
            </div>
        </LoadingProvider>
    );
};

export default App;
