import React from 'react';
import { useForm } from 'react-hook-form';
import { Box } from 'components/Box';
import { Checkbox } from 'components/Checkbox';
import { DaData } from 'components/DaData';
import { StepperAction, StepperContent, StepperContext } from 'components/Stepper';
import { STEP2 } from './Step2';
import { Loading } from 'components/Loading';
import { REQUIRED } from 'utils';

export const STEP3 = 'step-three';

export const Step3: React.FC = () => {
    const { resolve, getData, goAt } = React.useContext(StepperContext);
    const { handleSubmit, watch, control, errors } = useForm({
        defaultValues: getData(),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    const onSubmit = data => {
        resolve(data);
    };
    const back = () => {
        goAt(STEP2);
    };

    const IsLoading = false;
    return (
        <StepperContent
            onSubmit={handleSubmit(onSubmit)}
            actionsLeft={
                <>
                    <StepperAction type="button" onClick={back} arrowLeft white>
                        Назад
                    </StepperAction>
                    {IsLoading && <Loading />}
                </>
            }
            actionsRight={
                <>
                    <StepperAction align="right" type="submit" arrowRight>
                        Продолжить
                    </StepperAction>
                </>
            }
        >
            <div className="container-fluid row">
                <Box md={12}>
                    <DaData
                        control={control}
                        name="registrationAddress"
                        title="Адрес регистрации"
                        error={errors.registrationAddress}
                        rules={{ required: REQUIRED }}
                    />
                </Box>
                <Box md={12}>
                    <Checkbox
                        control={control}
                        name="liveEqualRegistrationAddress"
                        label="Я проживаю там, где зарегистрирован"
                    />
                </Box>
                {!watch('liveEqualRegistrationAddress') && (
                    <Box md={12}>
                        <DaData
                            control={control}
                            name="liveAddress"
                            error={errors.liveAddress}
                            title="Адрес фактического проживания"
                            rules={{ required: REQUIRED }}
                        />
                    </Box>
                )}
            </div>
        </StepperContent>
    );
};

export default Step3;
