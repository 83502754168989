import React, { useEffect, useState } from 'react';
import { TextField } from 'components/TextField';
import useStateEffects from 'react-state-effects';
import { Box } from 'components/Box';
import { StepperAction, StepperContent, StepperContext } from 'components/Stepper';
import { api } from 'api';
import { useForm } from 'react-hook-form';
import { STEP1 } from './Step1';
import { LoadingContext } from 'steps/loadingContext';
import { Loading } from 'components/Loading';
import { ApiResponse, ConfirmCodeResponse } from 'models';
import { ChannelContext } from 'channelContext';
import { SendAgainButton } from 'components/SendAgainButton';

export const Sms: React.FC = () => {
    const { channel } = React.useContext(ChannelContext);
    const { resolve, getData, setConfigData } = React.useContext(StepperContext);
    const {
        register,
        handleSubmit,
        control,
        formState: { isDirty, isValid },
    } = useForm({
        mode: 'onChange',
    });
    const { isLoading, setLoading } = React.useContext(LoadingContext);
    const [authError, setAuthError] = useState<string>(null);
    const [timer, setTimer] = useState<any>();
    const [count, setCount] = useStateEffects(0);
    const stepperData = getData();

    const closeSmsForm = () => {
        setConfigData({ smsForm: false });
    };

    const sendCode = async () => {
        if (count) return;
        try {
            setLoading(STEP1, true);
            setAuthError(null);
            const data: ApiResponse<null> = await api.sendCode(stepperData.phone);
            if (data.success) {
                startTimer();
            } else {
                setAuthError(data.error || 'Ошибка при отправке кода');
            }
            setLoading(STEP1, false);
        } catch (e) {
            setAuthError('Ошибка при отправке кода');
            setLoading(STEP1, false);
        }
    };

    const confirmCode = async (code: string) => {
        try {
            setAuthError(null);
            setLoading(STEP1, true);
            const data: ApiResponse<ConfirmCodeResponse> = await api.confirmCode(
                stepperData.phone,
                code,
                channel
            );
            if (data.success && data.result) {
                const { token } = data.result;
                resolve({ token });
                closeSmsForm();
            } else {
                setAuthError(data.error || 'Ошибка при подтверждении номера');
            }
            setLoading(STEP1, false);
        } catch (e) {
            setAuthError('Ошибка при подтверждении номера');
            setLoading(STEP1, false);
        }
    };

    const onSubmit = data => {
        confirmCode(data.code);
    };

    const startTimer = () => {
        setCount(state$ => [
            process.env.NODE_ENV === 'development' ? 5 : 60,
            () => {
                setTimer(setInterval(countDown, 1000));
            },
        ]);
    };

    const countDown = () => {
        setCount(state$ => [state$ - 1]);
    };

    const back = () => {
        closeSmsForm();
    };

    useEffect(() => {
        sendCode();
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (count < 1) {
            clearInterval(timer);
        }
    }, [count]);

    return (
        <StepperContent
            onSubmit={handleSubmit(onSubmit)}
            actionsLeft={
                <>
                    {isLoading(STEP1) ? (
                        <Loading />
                    ) : (
                        <StepperAction type="button" onClick={back} arrowLeft white>
                            Назад
                        </StepperAction>
                    )}
                </>
            }
            actionsRight={
                <StepperAction
                    align="right"
                    type="submit"
                    arrowRight
                    disabled={isLoading(STEP1) || !isDirty || !isValid}
                >
                    Продолжить
                </StepperAction>
            }
        >
            <div className="container-fluid">
                <Box md={12}>
                    <h3 className="hd3">Подтвердите телефон кодом из СМС</h3>
                </Box>
                <Box md={12}>
                    <TextField
                        name="code"
                        control={control}
                        error={!!authError && { message: authError }}
                        title="Код из СМС"
                        mask="99999"
                        inputRef={register({
                            required: true,
                        })}
                    />
                </Box>
                <Box md={12}>
                    <div className="send-code-block">
                        <SendAgainButton
                            text={
                                count
                                    ? `Отправить повторно через ${count}`
                                    : `Отправить повторно`
                            }
                            disabled={!!count || isLoading(STEP1)}
                            onClick={sendCode}
                        />
                    </div>
                </Box>
            </div>
        </StepperContent>
    );
};

export default Sms;
