import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { ChannelProvider } from 'channelContext';
import * as serviceWorker from './serviceWorker';

// Монтирование модуля
export function mount({ selector, channel = 'CITCARD_Web' }) {
    const query = new URLSearchParams(window.location.search);
    const provider = query.get('provider') || channel;
    const el = selector.replace('#', '');
    if (el && !!document.getElementById(el)) {
        document.getElementById(el).classList.add(`theme-${provider}`);

        ReactDOM.render(
            <ChannelProvider value={{ channel: provider }}>
                <App />
            </ChannelProvider>,
            document.getElementById(el)
        );
    } else {
        throw new Error('Module is not mounting');
    }
}

serviceWorker.unregister();
