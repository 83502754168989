import React, { useState, MouseEvent as ReactMouseEvent } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from 'components/Box';
import { Checkbox } from 'components/Checkbox';
import { StepperAction, StepperContent, StepperContext } from 'components/Stepper';
import { STEP3 } from './Step3';
import { Loading } from 'components/Loading';
import { api } from 'api';
import { LoadingContext } from 'steps/loadingContext';
import { Delivery } from 'components/Delivery';
import { Results, ApiResponse, CreateDraftResponse, FormData } from 'models';
//@ts-ignore
import cases from 'assets/docs/Prilozhenie_1_Usloviya_zakaza_karty.pdf';
import { useFileDownload } from 'hooks/useFileDownload';
import { ChannelContext } from '../channelContext';

export const STEP4 = 'step-four';

interface Props {
    setResult: (result: string) => void;
}

export const Step4: React.FC<Props> = ({ setResult }) => {
    const { channel } = React.useContext(ChannelContext);
    const { getData, goAt, setData } = React.useContext(StepperContext);
    const { register, handleSubmit, watch, errors, control } = useForm({
        defaultValues: getData(),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    const { isLoading, setLoading } = React.useContext(LoadingContext);
    const [selectedOption, setSelectedOption] = useState<string>();
    const { onDownload } = useFileDownload(
        channel,
        () => setLoading(STEP4, true),
        () => setLoading(STEP4, false)
    );

    const onTariffDownload = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        onDownload('tariffplanresidentcard');
    };

    const onSubmit = data => {
        const stepperData = getData();
        setData(data);
        submitForm({ ...stepperData, ...data });
    };

    const submitForm = async (formData: FormData) => {
        try {
            setLoading(STEP4, true);

            const data: ApiResponse<CreateDraftResponse> = await api.sendDraft(
                formData,
                'PUT'
            );
            if (data.success) {
                const { applicationId, token } = formData;
                sendApplication(applicationId, token);
            } else {
                setResult(Results.ERROR);
            }
        } catch {
            setResult(Results.ERROR);
        }
    };

    const sendApplication = async (applicationId: string, token: string) => {
        try {
            const data: ApiResponse<null> = await api.sendApplication(
                applicationId,
                token
            );

            if (data.success) {
                setResult(Results.SUCCESS);
            } else {
                setResult(Results.ERROR);
            }
        } catch (e) {
            setResult(Results.ERROR);
        }
    };

    const back = () => {
        goAt(STEP3);
    };
    const IsLoading = isLoading(STEP4);
    const deliveryAddress = watch('deliveryAddress');
    const deliveryBranchOfBank = watch('deliveryBranchOfBank');
    const approval = watch('approval1');

    const disableSubmit =
        IsLoading ||
        !selectedOption ||
        (selectedOption === 'bank' && !deliveryBranchOfBank) ||
        (selectedOption === 'delivery' && !deliveryAddress?.displayValue) ||
        !approval;

    return (
        <StepperContent
            onSubmit={handleSubmit(onSubmit)}
            actionsLeft={
                <>
                    {IsLoading ? (
                        <Loading />
                    ) : (
                        <StepperAction type="button" onClick={back} arrowLeft white>
                            Назад
                        </StepperAction>
                    )}
                </>
            }
            actionsRight={
                <>
                    <StepperAction align="right" type="submit" disabled={disableSubmit}>
                        Заказать карту
                    </StepperAction>
                </>
            }
        >
            <div className="container-fluid row">
                <Delivery
                    register={register}
                    errors={errors}
                    control={control}
                    setData={setData}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                />
                <Box md={12}>
                    <Checkbox
                        control={control}
                        name="approval1"
                        error={errors.approval1}
                        label={
                            <>
                                Я согласен(а) с{' '}
                                <a
                                    href={cases}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ zIndex: 100000 }}
                                    onClick={() => {
                                        window.open(cases, '_blank');
                                    }}
                                >
                                    условиями подачи онлайн-заявки на выпуск карты
                                </a>
                                <br />и передачей персональных данных в банк*
                            </>
                        }
                        rules={{ required: true }}
                    />
                </Box>
                <Box md={12}>
                    <button onClick={onTariffDownload} className="buttonLink">
                        Ознакомиться с тарифами
                    </button>
                </Box>
            </div>
        </StepperContent>
    );
};

export default Step4;
