import React from 'react';

interface Props {
    md?: number;
    pd?: number;
    dataTestId?: string;
    children: React.ReactNode;
}

export const Box: React.FC<Props> = ({
    md = 6,
    pd = 15,
    dataTestId = 'box_id',
    children,
}) => {
    return (
        <div className={`col-md-${md}`} data-testid={dataTestId}>
            <div className={`pd-${pd}`} data-testid="inner_div">
                {children}
            </div>
        </div>
    );
};
