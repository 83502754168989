import React, { useEffect } from 'react';
import CustomCheckbox from 'react-custom-checkbox';
import { Controller } from 'react-hook-form';
import checkboxImg from 'assets/images/checkbox.svg';

interface Props {
    checked?: boolean;
    error?: any;
    name?: string;
    label?: any;
    control: any;
    rules?: any;
}

export const Checkbox: React.FC<Props> = ({ name, label, error, control, rules }) => {
    useEffect(() => {
        //Предзагрузка изображения
        new Image().src = checkboxImg;
    }, []);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={props => {
                return (
                    <CustomCheckbox
                        name={name}
                        label={label}
                        borderColor={error ? '#e24f40' : '#1717171f'}
                        error={error}
                        checked={props.value}
                        size={20}
                        style={{ marginRight: 5 }}
                        containerClassName="custom-checkbox"
                        onChange={checked => {
                            props.onChange(checked);
                        }}
                        icon={
                            <div className="checkbox">
                                <img src={checkboxImg} alt="" />
                            </div>
                        }
                    />
                );
            }}
        />
    );
};

export default Checkbox;
