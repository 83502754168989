import React, { useEffect } from 'react';
import useStateEffects from 'react-state-effects';
import * as Actions from './actions';
import StepError from './error';
import * as Selectors from './selectors';
import { StepId, StepState } from './typings';

const contextFallback = () => {
    throw new Error('createStep invoked outside of Stepper scope');
};

export interface StepperController {
    createStep: Actions.CreateStep;
    removeStep: Actions.RemoveStep;
    updateStep: Actions.UpdateStep;
    goAt: Actions.goAt;
    resolve: Actions.Resolve;
    reject: Actions.Reject;
    isLoading: Selectors.IsLoading;
    getSteps: Selectors.GetSteps;
    getCurrentStep: Selectors.GetCurrentStep;
    getStep: Selectors.GetStep;
    getData: Selectors.GetData;
    setData: Selectors.SetData;
    getConfigData: Selectors.GetData;
    setConfigData: Selectors.SetData;
}

export const Context = React.createContext<StepperController>({
    createStep: contextFallback,
    getCurrentStep: () => undefined,
    getData: () => undefined,
    setData: () => undefined,
    getConfigData: () => undefined,
    setConfigData: () => undefined,
    getStep: () => undefined,
    getSteps: () => [],
    goAt: contextFallback,
    isLoading: () => false,
    reject: contextFallback,
    removeStep: contextFallback,
    resolve: contextFallback,
    updateStep: contextFallback,
});

export type OnResolve = (stepId: StepId) => void;
export type OnReject = (stepId: StepId) => void;

interface Props {
    children: (context: StepperController) => React.ReactNode;
    contextRef?: React.MutableRefObject<StepperController>;
    onResolve?: OnResolve;
    onReject?: OnReject;
    initialStep?: StepId;
    defaultData: any;
    showAuthBanner: boolean;
}

interface ConfigData {
    selectedSettlement: any;
    selectedDepartment: any;
    smsToken: null;
    smsForm: boolean;
    showAuthBanner: boolean;
}

interface State {
    current: StepId;
    data: any;
    configData: ConfigData;
    steps: Record<StepId, StepState>;
    stepIndex: StepId[];
}

const StepperProvider: React.FunctionComponent<Props> = ({
    initialStep,
    onResolve,
    onReject,
    contextRef,
    children,
    defaultData,
    showAuthBanner,
}) => {
    const [state, setState] = useStateEffects<State>({
        current: initialStep,
        data: defaultData,
        configData: {
            selectedDepartment: null,
            selectedSettlement: null,
            smsToken: null,
            smsForm: false,
            showAuthBanner: showAuthBanner,
        },
        steps: {},
        stepIndex: [],
    });

    const getIndex = (stepId: StepId) => state.stepIndex.indexOf(stepId);
    const getNextStepId = (stepId: StepId) => {
        const index = getIndex(stepId);
        const nextIndex = index + 1 < state.stepIndex.length ? index + 1 : index;

        return state.stepIndex[nextIndex];
    };

    const createStep: Actions.CreateStep = (stepId, config) => {
        setState(state$ => {
            const stepState = state$.steps[stepId] || config;
            const index = stepState.index || state$.stepIndex.length;
            const current = state$.current || stepId;
            const completed =
                state$.stepIndex.indexOf(state$.current) < 0 && current !== stepId;

            return [
                {
                    ...state$,
                    current,
                    stepIndex: [
                        ...state$.stepIndex.slice(0, index),
                        stepId,
                        ...state$.stepIndex.slice(index),
                    ],
                    steps: {
                        ...state$.steps,
                        [stepId]: {
                            completed,
                            index,
                            loading: false,
                            stepId,
                            ...stepState,
                        },
                    },
                },
            ];
        });
    };

    const removeStep: Actions.RemoveStep = stepId => {
        setState(state$ => [
            {
                ...state$,
                stepIndex: state$.stepIndex.filter(stepId$ => stepId$ !== stepId),
            },
        ]);
    };

    const updateStep: Actions.UpdateStep = (stepId, stepState) =>
        setState(state$ => [
            {
                ...state$,
                steps: {
                    ...state$.steps,
                    [stepId]: {
                        ...state$.steps[stepId],
                        ...stepState,
                    },
                },
            },
        ]);

    const goAt: Actions.goAt = stepId =>
        setState(state$ => [
            {
                ...state$,
                current: stepId,
            },
        ]);

    const getSteps: Selectors.GetSteps = () => {
        return state.stepIndex.map(stepId => state.steps[stepId]);
    };

    const isLoading = () => getSteps().some(step => step.loading);

    const getStep: Selectors.GetStep = stepId => state.steps[stepId];

    const getCurrentStep: Selectors.GetCurrentStep = () => getStep(state.current);

    const getData: Selectors.GetData = () => {
        return state.data || {};
    };

    const getConfigData: Selectors.GetData = () => {
        return state.configData || {};
    };

    const setData: Selectors.SetData = (newData, callback?) => {
        setState(({ current, data, ...state$ }) => [
            {
                ...state$,
                current: current,
                data: {
                    ...data,
                    ...newData,
                },
            },
            callback && (() => callback(contextRef.current)),
        ]);
    };

    const setConfigData: Selectors.SetData = (newData, callback?) => {
        setState(({ current, configData, ...state$ }) => [
            {
                ...state$,
                current: current,
                configData: {
                    ...configData,
                    ...newData,
                },
            },
            callback && (() => callback(contextRef.current)),
        ]);
    };

    const resolve: Actions.Resolve = newData => {
        setState(({ current, data, steps, ...state$ }) => [
            {
                ...state$,
                current: getNextStepId(current),
                data: {
                    ...data,
                    ...newData,
                },
                steps: {
                    ...steps,
                    [current]: {
                        ...steps[current],
                        completed: true,
                        error: undefined,
                    },
                },
            },
            onResolve && (() => onResolve(current)),
        ]);
    };

    const reject: Actions.Reject = (message, description) =>
        setState(({ current, steps, ...state$ }) => [
            {
                ...state$,
                current,
                steps: {
                    ...steps,
                    [current]: {
                        ...steps[current],
                        completed: false,
                        error: new StepError(message, description),
                    },
                },
            },
            onReject && (() => onReject(current)),
        ]);

    const context = {
        createStep,
        getCurrentStep,
        getData,
        setData,
        getConfigData,
        setConfigData,
        getStep,
        getSteps,
        goAt,
        isLoading,
        reject,
        removeStep,
        resolve,
        updateStep,
    };

    useEffect(() => {
        if (contextRef) {
            contextRef.current = context;
        }
    }, [state]);

    return <Context.Provider value={context}>{children(context)}</Context.Provider>;
};

export default StepperProvider;
