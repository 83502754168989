import React, { useState } from 'react';
import { Controller, FieldErrors } from 'react-hook-form';
import cx from 'classnames';
import AsyncSelect from 'react-select/async';
import { api } from 'api';
import { AddressContract, ApiResponse } from 'models';
import { StepperContext } from 'components';

interface Props {
    name: string;
    title?: string;
    error?: FieldErrors;
    control: any;
    rules?: any;
    fiasId?: string;
}

export const DaData: React.FC<Props> = props => {
    const { setData, getData } = React.useContext(StepperContext);
    const stepperData = getData();
    const { name, title, error, control, rules, fiasId, ...other } = props;
    const [inputValue, setInputValue] = useState(
        control.defaultValuesRef.current[name]?.displayValue || ''
    );
    const [selectedValue, setSelectedValue] = useState(
        control.defaultValuesRef.current[name]
    );
    const [inputError, setInputError] = useState<string>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [timer, setTimer] = useState(null);

    const getDaData = async (
        inputValue: string,
        callback: (options: AddressContract[]) => void
    ) => {
        try {
            const data: ApiResponse<AddressContract[]> = await api.suggestAddress(
                inputValue,
                fiasId
            );
            if (data.success) {
                callback(data.result);
                setInputError(null);
            } else {
                setInputError('Ошибка получения адресов');
            }
        } catch (e) {
            setInputError('Ошибка получения адресов');
        } finally {
            setLoading(false);
        }
    };

    const loadOptions = (
        inputValue: string,
        callback: (options: AddressContract[]) => void
    ) => {
        if (timer) clearTimeout(timer);
        setLoading(true);
        setTimer(setTimeout(() => getDaData(inputValue, callback), 300));
    };

    const handleInputChange = (newValue: string, { action }) => {
        if (action === 'input-blur') {
            setInputValue(selectedValue?.displayValue);
        }
        if (action === 'input-change') {
            setInputValue(newValue);
        }
        return newValue;
    };

    const handleChange = (newValue: AddressContract, onChange) => {
        setSelectedValue(newValue);
        setTimeout(() => {
            setInputValue(newValue.displayValue);
        }, 300);
        if (newValue.value) {
            onChange(newValue);
            setData({ ...stepperData, [`${name}`]: newValue });
            setInputError(null);
        } else {
            setInputError('Выбран некорректный адрес');
            onChange(null);
        }
    };

    return (
        <div className="text-field__container" data-testid="dadata_textfield_id">
            <span className="text-field__title">
                {title + (rules?.required ? '*' : '')}
            </span>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={props => {
                    return (
                        <>
                            <AsyncSelect
                                name={name}
                                className={cx('react-select', {
                                    'react-select__error': !!inputError,
                                })}
                                classNamePrefix="react-select"
                                cacheOptions
                                loadOptions={loadOptions}
                                placeholder="Введите адрес"
                                noOptionsMessage={() => 'Нет результатов'}
                                loadingMessage={() => 'Загрузка...'}
                                onInputChange={handleInputChange}
                                isLoading={loading}
                                inputValue={inputValue}
                                styles={{
                                    singleValue: (baseStyles, state) => ({
                                        ...baseStyles,
                                        opacity: 0,
                                    }),
                                }}
                                defaultValue={props.value}
                                isOptionSelected={() => false}
                                onChange={value => handleChange(value, props.onChange)}
                                getOptionLabel={(option: AddressContract) =>
                                    option.displayValue || ''
                                }
                                {...other}
                            />
                            {(!!inputError || !!error?.message) && (
                                <div className={`text-field__error-message`}>
                                    {inputError || error?.message}
                                </div>
                            )}
                        </>
                    );
                }}
            />
        </div>
    );
};
