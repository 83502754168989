import React, { useEffect } from 'react';
import { Box } from 'components/Box';
import { RadioGroup, Radio } from 'components/Radio/index';
import { DaData } from 'components/DaData';
import { CustomHeader } from 'components/CustomHeader';
import { Select } from 'components/Select';
import { StepData } from 'components/Stepper';
import { Settlement } from 'models';
import { isEmpty } from 'lodash';
import { Loading } from 'components/Loading';
import { DeliveryOption } from 'models';
import { Controller } from 'react-hook-form';
interface Props {
    register: any;
    errors: any;
    control: any;
    settlement: Settlement;
    setData: any;
    errorSettlement: string;
    setSelectedOption: (value: string) => void;
    selectedOption: string;
    loading: boolean;
    deliveryOption: DeliveryOption;
    stepperData: StepData;
}

export const Choice: React.FC<Props> = ({
    register,
    errors,
    control,
    settlement,
    setData,
    setSelectedOption,
    selectedOption,
    loading,
    errorSettlement,
    deliveryOption,
    stepperData,
}) => {
    const { hasDelivery, branches: bankBranches } = deliveryOption || {};
    const handleSelectedOption = value => {
        setData({ ...stepperData, deliveryBranchOfBank: null, deliveryAddress: null });
        setSelectedOption(value);
    };

    useEffect(() => {
        if (!selectedOption) {
            if (stepperData.deliveryBranchOfBank) {
                setSelectedOption('bank');
            } else if (stepperData.deliveryAddress) {
                setSelectedOption('delivery');
            }
        }
    }, [stepperData, selectedOption]);

    const handleSelectDep = value => {
        setData({ deliveryBranchOfBank: value });
    };

    const choice = Boolean(hasDelivery && !isEmpty(bankBranches));

    const isDelivery = Boolean(
        selectedOption === 'delivery' || (hasDelivery && isEmpty(bankBranches))
    );
    const isBank = Boolean(
        selectedOption === 'bank' || (!hasDelivery && !isEmpty(bankBranches))
    );

    if (loading) return <Loading />;
    if (errorSettlement)
        return (
            <Box md={12} pd={0}>
                <div className="text-field__error-message" style={{ padding: '0 15px' }}>
                    {errorSettlement}
                </div>
            </Box>
        );

    return (
        <>
            {/**Выбор, если все доступно*/}
            {choice && (
                <Box md={12} dataTestId="delivery_option_id">
                    <Controller
                        name="deliveryOption"
                        control={control}
                        render={props => {
                            return (
                                <RadioGroup
                                    name="deliveryOption"
                                    onChange={e => {
                                        props.onChange(e);
                                        handleSelectedOption(e);
                                    }}
                                    selectedValue={selectedOption}
                                    title="Выберите способ получения карты"
                                >
                                    <Radio
                                        value="delivery"
                                        label="Бесплатная доставка курьером"
                                    />
                                    <Radio value="bank" label="Забрать из отделения" />
                                </RadioGroup>
                            );
                        }}
                    />
                </Box>
            )}

            {/**Дадата в которую нужно город передавать*/}
            {isDelivery && (
                <Box md={12} dataTestId="delivery_by_courier_id">
                    <CustomHeader
                        h="Доставка курьером"
                        span="Доставим карту куда и когда вам удобно"
                    />
                    <DaData
                        control={control}
                        name="deliveryAddress"
                        title="Адрес доставки"
                        fiasId={settlement.fiasId}
                        rules={{ required: true }}
                    />
                </Box>
            )}

            {/**Отделения */}
            {isBank && (
                <Box md={12} dataTestId="bank_delivery_id">
                    <CustomHeader
                        h="Забрать из отделения"
                        span="Выберите удобное для вас отделение"
                    />
                    {bankBranches && (
                        <Select
                            name="deliveryBranchOfBank"
                            title="Отделение АББ"
                            placeholder="Выберите из списка"
                            control={control}
                            defaultValue={stepperData?.deliveryBranchOfBank}
                            error={errors.deliveryBranchOfBank}
                            isSearchable={true}
                            required
                            onChangeSelect={handleSelectDep}
                            options={bankBranches?.map(i => ({
                                label: i.address,
                                value: i.code,
                            }))}
                            inputRef={register({
                                required: true,
                            })}
                            opacity={1}
                        />
                    )}
                </Box>
            )}
        </>
    );
};
