import React from 'react';
import gosUslugi from 'assets/images/gosUslugi.svg';
import ok from 'assets/images/ok.svg';

export const Success: React.FC = () => {
    return (
        <div className="success" data-testid="success_auth_id">
            <div className="inner">
                <img src={gosUslugi} alt="" className="icon-title" />
                <div>
                    <h5 className="esia-auth-banner__text">
                        Данные успешно синхронизированы
                    </h5>
                    <span>
                        Проверьте все заполненные поля и укажите недостающие данные
                    </span>
                </div>
            </div>
            <img src={ok} alt="" className="success-icon" />
        </div>
    );
};
