import React from 'react';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';

interface Props {
    control: any;
    name: string;
    className: any;
    placeholder?: string;
    defaultValue?: string;
    disabled?: boolean;
    required?: boolean;
    mask?: string;
    inputRef?: any;
    onChange?: any;
    rules: any;
    maskPlaceholder?: string;
    onChangeCallback?: any;
}

export const MaskedInput: React.FC<Props> = ({
    mask,
    control,
    name,
    inputRef,
    rules,
    onChangeCallback,
    ...other
}) => {
    if (!control)
        return (
            <InputMask
                mask={mask}
                name={name}
                inputRef={inputRef}
                {...other}
                data-testid="input_mask_id"
            />
        );
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ onChange, value, name, ref }) => (
                <InputMask
                    mask={mask}
                    onChange={e => {
                        onChange(e);
                        if (onChangeCallback) onChangeCallback(e);
                    }}
                    value={value}
                    name={name}
                    inputRef={ref}
                    {...other}
                />
            )}
        />
    );
};
