import React from 'react';
import { Box, Button, DEFAULT_ESIA_ERROR } from 'components';
import esia from 'assets/images/esia.svg';
import { api } from 'api';
import { ApiResponse, EsiaAuthResponse, LocalStorageKeys } from 'models';

interface Props {
    onErrorRequest: (error: string) => void;
}

export const Login: React.FC<Props> = ({ onErrorRequest }) => {
    const [loading, setLoading] = React.useState<boolean>(false);

    const getEsiaAuth = async () => {
        setLoading(true);
        let callback = window.location.href;

        try {
            const data: ApiResponse<EsiaAuthResponse> = await api.esiaAuth(callback);
            if (data.success) {
                const { esiaUrl, requestId } = data.result;
                localStorage.setItem(LocalStorageKeys.EsiaRequestId, requestId);
                document.location.href = esiaUrl;
                onErrorRequest('');
            } else {
                onErrorRequest(DEFAULT_ESIA_ERROR);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            onErrorRequest(DEFAULT_ESIA_ERROR);
            setLoading(false);
        }
    };

    return (
        <>
            <div
                className="container-fluid row esia-auth-banner__wrapper"
                data-testid="login_id"
            >
                <Box md={7}>
                    <span className="esia-auth-banner__text">
                        Заполните заявку автоматически с вашей учётной записью портала
                        Госуслуг РФ
                    </span>
                </Box>
                <Box md={5}>
                    <Button
                        fullWidth
                        className="esia-auth-banner__button"
                        disabled={loading}
                        onClick={getEsiaAuth}
                    >
                        <img
                            src={esia}
                            alt=""
                            className="esia-auth-banner__button_image"
                        />
                        Войти через ЕСИА
                    </Button>
                </Box>
            </div>
        </>
    );
};
