import { Channel } from 'models';

export const getWidgetTitle = (channel: Channel) => {
    switch (channel) {
        case Channel.Zabota:
            return 'Анкета на выпуск карты';
        default:
            return 'Анкета на выпуск Карты жителя Республики Татарстан';
    }
};
