import React from 'react';
import classnames from 'classnames';
import StepperProvider, { OnReject, OnResolve, StepperController } from './context';
import HeaderWrapper from './HeaderWrapper';
import StepperProgress from './progress';
import { StepId } from './typings';
import Sms from 'steps/Sms';

interface Props {
    initialStep?: StepId;
    onResolve?: OnResolve;
    onReject?: OnReject;
    contextRef?: React.MutableRefObject<StepperController>;
    className?: string;
    vertical?: boolean;
    defaultData: any;
    showAuthBanner: boolean;
}

const CLASSNAME = 'stepper';

const Stepper: React.FC<Props> = ({
    onResolve,
    onReject,
    initialStep,
    className,
    vertical = false,
    contextRef,
    children,
    defaultData,
    showAuthBanner,
}) => (
    <StepperProvider
        initialStep={initialStep}
        onResolve={onResolve}
        onReject={onReject}
        contextRef={contextRef}
        defaultData={defaultData}
        showAuthBanner={showAuthBanner}
    >
        {({ getSteps, getCurrentStep, getConfigData }) => {
            const currentStep = getCurrentStep();
            const steps = getSteps();
            const cData = getConfigData();
            const isLoading = steps.some(step => step.loading);
            const horizontal = !vertical;

            return (
                <div
                    className={classnames(CLASSNAME, className, {
                        [`${CLASSNAME}--horizontal`]: horizontal,
                        [`${CLASSNAME}--vertical`]: vertical,
                    })}
                >
                    {children}
                    <HeaderWrapper steps={steps} className={CLASSNAME} />
                    {isLoading && (
                        <StepperProgress className={`${CLASSNAME}__progress`} />
                    )}
                    {steps.map((step, idx) => (
                        <React.Fragment key={step.stepId}>
                            <section className={`${CLASSNAME}__section`}>
                                {step === currentStep && !cData.smsForm && step.children}
                            </section>
                        </React.Fragment>
                    ))}
                    <section className={`${CLASSNAME}__section`}>
                        {cData.smsForm && <Sms />}
                    </section>
                </div>
            );
        }}
    </StepperProvider>
);

export default Stepper;
