import React from 'react';

export const Loading: React.FC = () => (
    <div className={`loading-ring`} data-testid="loading_id">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

export default Loading;
