import * as React from 'react';
import cx from 'classnames';
import { SelectedIcon, NotSelectedIcon } from './icons';
import './Radio.scss';

/**react-radio-input */
export interface RadioGroupContextInterface {
    disabled?: boolean;
    name: string;
    onChange: Function;
    selectedValue?: string | number | boolean;
}

const RadioContext = React.createContext<RadioGroupContextInterface | null>(null);

export const useRadioGroup = () => {
    const context = React.useContext(RadioContext);

    if (!context) {
        throw new Error(
            'A `<Radio />` component can only be used inside a parent `<RadioGroup />`.'
        );
    }
    return context;
};

export interface RadioGroupInterface {
    children: React.ReactNode;
    component?: React.ElementType;
    disabled?: boolean;
    name: string;
    onChange: Function;
    selectedValue?: string | number | boolean;
    title?: string;
}

export const RadioGroup = ({
    children,
    disabled,
    name,
    onChange,
    selectedValue,
    title,
}: RadioGroupInterface) => {
    return (
        <>
            {title && <span className="form_radio_title">{title}</span>}
            <RadioContext.Provider value={{ disabled, name, onChange, selectedValue }}>
                <div className="form_radio" data-testid="radio_group_id">
                    {children}
                </div>
            </RadioContext.Provider>
        </>
    );
};

export interface RadioInterface {
    disabled?: boolean;
    id?: string;
    value: string;
    label: string;
}

export const Radio = ({ disabled, value, label, ...props }: RadioInterface) => {
    const { disabled: disabledGroup, name, onChange, selectedValue } = useRadioGroup();
    const isSelected = selectedValue === value;
    const isDisabled = disabled || disabledGroup;

    const handleChange = () => {
        if (!isDisabled) {
            onChange(value);
        }
    };

    return (
        <div
            className={cx('form_radio_btn', { form_radio_btn__active: isSelected })}
            onClick={handleChange}
            data-testid={`${value}_radio_id`}
        >
            {isSelected ? <SelectedIcon /> : <NotSelectedIcon />}
            <label htmlFor={`radio-${name}`}>{label}</label>
        </div>
    );
};
