import React from 'react';
import { TextField } from 'components/TextField';
import { Box } from 'components/Box';
import { StepperAction, StepperContent, StepperContext } from 'components/Stepper';
import { useForm } from 'react-hook-form';
import { EsiaAuthBanner } from 'components/EsiaAuthBanner';
import { STEP2 } from './Step2';
import { isValidBirthDate, REQUIRED, NAME_RULE, isValidDate, PHONE_RULE } from 'utils';

export const STEP1 = 'step-one';

function ucFirst(str: string) {
    return str[0].toUpperCase() + str.slice(1);
}

interface Props {
    esiaError: string;
}

export const Step1: React.FC<Props> = ({ esiaError }) => {
    const { goAt, getData, setData, setConfigData, getConfigData } =
        React.useContext(StepperContext);

    const config = getConfigData();
    const { register, handleSubmit, errors, control } = useForm({
        defaultValues: getData(),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    let stepperData = getData();
    const onSubmit = data => {
        setData({
            ...data,
            lastName: ucFirst(data.lastName).trim(),
            firstName: ucFirst(data.firstName).trim(),
            middleName: !!data.middleName ? ucFirst(data.middleName).trim() : null,
        });
        if (stepperData.token) {
            goAt(STEP2);
        } else {
            setConfigData({ smsForm: true });
        }
    };

    return (
        <StepperContent
            onSubmit={handleSubmit(onSubmit)}
            actionsRight={
                <StepperAction align="right" type="submit" arrowRight>
                    Продолжить
                </StepperAction>
            }
        >
            <div className="container-fluid row flex flex-wrap">
                {config.showAuthBanner && (
                    <Box md={12}>
                        <EsiaAuthBanner esiaError={esiaError} />
                    </Box>
                )}
                <Box>
                    <TextField
                        name="lastName"
                        title="Фамилия"
                        error={errors.lastName}
                        required
                        inputRef={register({
                            required: REQUIRED,
                            pattern: NAME_RULE,
                        })}
                    />
                </Box>
                <Box>
                    <TextField
                        name="firstName"
                        title="Имя"
                        error={errors.firstName}
                        required
                        inputRef={register({
                            required: REQUIRED,
                            pattern: NAME_RULE,
                        })}
                    />
                </Box>
                <Box>
                    <TextField
                        name="middleName"
                        title="Отчество"
                        error={errors.middleName}
                        inputRef={register({
                            pattern: NAME_RULE,
                        })}
                    />
                </Box>
                <Box>
                    <TextField
                        control={control}
                        name="birthdayDate"
                        title="Дата рождения"
                        placeholder="ДД.ММ.ГГГГ"
                        mask="99.99.9999"
                        required
                        error={errors.birthdayDate}
                        rules={{
                            required: REQUIRED,
                            validate: {
                                moreThan14Years: value =>
                                    (value && isValidBirthDate(value)) ||
                                    'Карту можно оформить с 18 лет',
                                invalidDate: value =>
                                    (value && isValidDate(value)) || 'Некорректная дата',
                            },
                        }}
                    />
                </Box>
                <Box>
                    <TextField
                        control={control}
                        name="phone"
                        title="Номер телефона"
                        placeholder="+7"
                        mask="+79999999999"
                        error={errors.phone}
                        required
                        onChangeCallback={e => {
                            setData({ token: null });
                        }}
                        rules={{
                            required: REQUIRED,
                            pattern: PHONE_RULE,
                        }}
                    />
                </Box>
            </div>
        </StepperContent>
    );
};

export default Step1;
