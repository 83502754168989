import React from 'react';
import cx from 'classnames';
import { FieldErrors } from 'react-hook-form';
import { MaskedInput } from './MaskedInput';

interface Props {
    name: string;
    placeholder?: string;
    defaultValue?: string;
    disabled?: boolean;
    required?: boolean;
    title?: string;
    mask?: string;
    error?: FieldErrors;
    inputRef?: any;
    onChange?: any;
    iconLeft?: any;
    iconRight?: any;
    control?: any;
    rules?: any;
    maskPlaceholder?: string;
    onChangeCallback?: any;
}

export const TextField: React.FC<Props> = ({
    name,
    title,
    error,
    disabled,
    mask,
    required,
    iconLeft,
    iconRight,
    inputRef,
    control,
    rules,
    onChangeCallback,
    ...other
}) => {
    const com = (
        <div className="text-field__container">
            {!!iconLeft && (
                <img src={iconLeft} alt="" className="text-field__container__left-icon" />
            )}
            <MaskedInput
                mask={mask}
                rules={rules}
                control={control}
                name={name}
                onChangeCallback={onChangeCallback}
                className={cx(
                    'text-field__input',
                    { 'text-field__input_error': error },
                    { 'text-field__input_disabled': disabled },
                    { 'text-field__input_icon-padding ': !!iconLeft }
                )}
                required={required}
                disabled={disabled}
                inputRef={inputRef}
                {...other}
            />
            {!!iconRight && (
                <img
                    src={iconRight}
                    alt=""
                    className="text-field__container__right-icon"
                />
            )}
            {error?.message && (
                <div className={`text-field__error-message`}>{error?.message}</div>
            )}
        </div>
    );
    if (!title) return com;
    return (
        <div className="text-field">
            <span className="text-field__title" data-testid="text_field_title_id">
                {title + (required ? '*' : '')}
            </span>
            {com}
        </div>
    );
};
