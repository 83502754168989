import React, { useState, useCallback, useEffect, useContext } from 'react';
import cx from 'classnames';
import AsyncSelect from 'react-select/async';
import { isEmpty } from 'lodash';
import { Controller } from 'react-hook-form';
import { Box } from 'components/Box';
import { StepperContext } from 'components/Stepper';
import { Choice } from './Choice';
import { Settlement, DeliveryOption, ApiResponse } from 'models';
import { api } from 'api';

interface Props {
    register: any;
    errors: any;
    control: any;
    setData: any;
    setSelectedOption: (value: string) => void;
    selectedOption: string;
}

export const Delivery: React.FC<Props> = ({
    register,
    errors,
    control,
    setData,
    setSelectedOption,
    selectedOption,
}) => {
    const { getData } = useContext(StepperContext);
    const [settlement, setSettlement] = useState<Settlement>();
    const [inputValue, setInputValue] = useState<string>('');
    const [deliveryOption, setDeliveryOption] = useState<DeliveryOption>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorRequest, setError] = useState<string>(null);
    const [endpointsError, setEndpointsError] = useState<string>(null);
    const [timer, setTimer] = useState(null);

    const stepperData = getData();

    const getSettlements = async (
        inputValue: string,
        callback: (options: Settlement[]) => void
    ) => {
        try {
            const data: ApiResponse<Settlement[]> = await api.settlementsDelivery(
                inputValue
            );
            if (data.success) {
                callback(data.result);
                setError(null);
            } else {
                setError(data.error);
                setLoading(false);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError('Ошибка при обращении к DaData');
            setLoading(false);
        }
    };

    const loadOptions = (
        inputValue: string,
        callback: (options: Settlement[]) => void
    ) => {
        if (timer) clearTimeout(timer);
        if (inputValue.length > 2) {
            setLoading(true);
            setTimer(setTimeout(() => getSettlements(inputValue, callback), 300));
        } else {
            callback([]);
        }
    };

    const getDeliveryOptions = async () => {
        setLoading(true);
        setEndpointsError(null);
        try {
            const data: ApiResponse<DeliveryOption> = await api.endpointDelivery(
                settlement
            );
            if (data.success) {
                if (!isEmpty(data.result)) {
                    setDeliveryOption({
                        hasDelivery: data.result.hasDelivery,
                        branches: data.result.branches,
                    });
                    setSelectedOption(null);
                } else {
                    setDeliveryOption(null);
                    setSelectedOption('pickUp');
                    setEndpointsError(data.error);
                }
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setEndpointsError(
                'При получении данных произошла ошибка. Попробуйте выбрать другой город'
            );
        }
    };

    useEffect(() => {
        if (settlement?.value) getDeliveryOptions();
    }, [settlement]);

    const changeSettlement = useCallback(val => {
        setData({ ...stepperData, deliveryBranchOfBank: null, deliveryAddress: null });
        setSettlement(val);
    }, []);

    const handleChangeSettlement = (newValue: Settlement, onChange) => {
        setTimeout(() => {
            setInputValue(newValue?.displayValue);
        }, 300);
        if (newValue?.value) {
            changeSettlement(newValue);
        } else {
            onChange(null);
        }
    };

    const handleInputChange = (newValue: string, { action }) => {
        if (action === 'input-blur') {
            setInputValue(settlement?.displayValue);
        }
        if (action === 'input-change') {
            setInputValue(newValue);
        }
        return newValue;
    };

    const errorSettlement = Boolean(
        deliveryOption &&
            !deliveryOption?.hasDelivery &&
            isEmpty(deliveryOption?.branches)
    )
        ? 'В этом населенном пункте нет отделений и нет доставки. Выберите другой населенный пункт'
        : null;

    return (
        <>
            <Box md={12}>
                <div className="text-field">
                    <span className="text-field__title">
                        В каком населенном пункте хотите получить карту?*
                    </span>
                    <Controller
                        name="settlement"
                        control={control}
                        error={Boolean(errorSettlement)}
                        render={props => {
                            return (
                                <>
                                    <AsyncSelect
                                        name="settlement"
                                        isClearable
                                        className={cx('react-select', {
                                            'react-select__error': !!errorRequest,
                                        })}
                                        classNamePrefix="react-select"
                                        cacheOptions
                                        loadOptions={loadOptions}
                                        placeholder="Введите адрес"
                                        onInputChange={handleInputChange}
                                        inputValue={inputValue}
                                        isOptionSelected={() => false}
                                        noOptionsMessage={() => 'Нет результатов'}
                                        loadingMessage={() => 'Загрузка...'}
                                        isLoading={loading}
                                        styles={{
                                            singleValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                opacity: 0,
                                            }),
                                        }}
                                        defaultValue={props.value}
                                        onChange={value =>
                                            handleChangeSettlement(value, props.onChange)
                                        }
                                        getOptionLabel={(option: Settlement) =>
                                            option.displayValue || ''
                                        }
                                    />
                                    {!!errorRequest && (
                                        <div className={`text-field__error-message`}>
                                            {errorRequest}
                                        </div>
                                    )}
                                </>
                            );
                        }}
                    />
                </div>
                {!!endpointsError && (
                    <div className={`text-field__error-message`}>{endpointsError}</div>
                )}
            </Box>
            {settlement && (
                <Choice
                    register={register}
                    errors={errors}
                    control={control}
                    setData={setData}
                    settlement={settlement}
                    setSelectedOption={setSelectedOption}
                    selectedOption={selectedOption}
                    loading={loading}
                    errorSettlement={errorSettlement}
                    deliveryOption={deliveryOption}
                    stepperData={stepperData}
                />
            )}
        </>
    );
};
