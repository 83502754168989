import React from 'react';

export const NotSelectedIcon: React.FC = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="not_selected_icon_id"
    >
        <path
            d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
            fill="white"
        />
        <path
            d="M10 18C5.58172 18 2 14.4183 2 10H-2C-2 16.6274 3.37258 22 10 22V18ZM18 10C18 14.4183 14.4183 18 10 18V22C16.6274 22 22 16.6274 22 10H18ZM10 2C14.4183 2 18 5.58172 18 10H22C22 3.37258 16.6274 -2 10 -2V2ZM10 -2C3.37258 -2 -2 3.37258 -2 10H2C2 5.58172 5.58172 2 10 2V-2Z"
            fill="#171717"
            fillOpacity="0.12"
        />
    </svg>
);

export default NotSelectedIcon;
