/* eslint-disable */

const insertEnvVars = (value: unknown) => {
    const isValidVar = typeof value === 'string' && !/\${/.test(value);

    return isValidVar ? value : '';
};

const { REACT_APP_API_URL = insertEnvVars('${REACT_APP_API_URL}') } = process.env;

export default {
    API_URL: REACT_APP_API_URL,
};
