import classnames from 'classnames';
import * as React from 'react';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
    actionsRight?: any;
    actionsLeft?: any;
}

const StepperContent: React.FunctionComponent<Props> = ({
    className,
    children,
    actionsRight,
    actionsLeft,
    ...props
}) => (
    <form
        {...props}
        className={classnames('stepper__content', className)}
        noValidate
        data-testid="form_id"
    >
        {children}
        <footer className="stepper__content__actions">
            {actionsLeft && <div className="stepper--left">{actionsLeft}</div>}
            {actionsRight && (
                <div className="stepper__content__actions--right">{actionsRight}</div>
            )}
        </footer>
    </form>
);

export default StepperContent;
