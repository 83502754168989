import React from 'react';

interface Props {
    h?: string;
    span?: string;
}

export const CustomHeader: React.FC<Props> = ({ h, span }) => {
    return (
        <div className="custom-header" data-testid="custom_header_id">
            <h3>{h}</h3>
            <span>{span}</span>
        </div>
    );
};
