import React, { useState, useRef } from 'react';
import Header from './header';
import { StepState } from './typings';

const STEP_WIDTH = 208;

interface Props {
    steps: StepState[];
    className: string;
}

export const HeaderWrapper: React.FC<Props> = ({ steps, className }) => {
    const ref = useRef(null);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    React.useEffect(() => {
        ref.current.scrollLeft = currentIndex * STEP_WIDTH;
    }, [currentIndex]);

    return (
        <header
            className={`${className}__header`}
            ref={ref}
            data-testid="header_wrapper_id"
        >
            {steps.map((step, idx) => (
                <React.Fragment key={step.stepId}>
                    <Header
                        stepId={step.stepId}
                        index={idx + 1}
                        setCurrentIndex={setCurrentIndex}
                    />
                </React.Fragment>
            ))}
        </header>
    );
};

export default HeaderWrapper;
